import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import config from "../../env.json";

interface userInfoDetails {
    // Id:String,
    // EmployeeV_Nbr: String,
    // EmployeeEmail_Adr: String,
    // EmployeePh_Nbr: String,
    // EmployeeVCard_Url:String,
    // EmployeeQR_Cd:String,
    // EmployeeFull_Nm:String,
    // EmployeeMsgorWhatsAppChannelType:boolean,
    timeZone:String,
    language:String,
    locale:String,
    personalisationId:String,
    callType:String
    showCallType:Boolean,
    notificationType:String,
    bottomNavBarFlag:String,
    showSuccessSnackBar:boolean,
    showErrorSnackBar:boolean,
    optOutKeywords:any,
    optInKeywords:any,
    EmployeeTerritoryId:any,
    campaignName:String,
    EmployeePermissionCountry_Cd:String,
    transData:Object,
    personalizationDateFormat:String,
    oooValue: boolean,
    showchatfooter:boolean,
    EmployeePersonalizationPhone_Allow_CountryCd:string,
    ErrorMessage :string
  }
const initialState: userInfoDetails = {  
    timeZone:'',
    language:'',
    locale:'',
    personalisationId:'',
    callType:'',
    showCallType:false,
    notificationType:'',
    bottomNavBarFlag:'Y',
    showSuccessSnackBar:false,
    showErrorSnackBar:false,
    optOutKeywords:[],
    optInKeywords:[],
    EmployeeTerritoryId:'',
    campaignName:'',
    EmployeePermissionCountry_Cd:'',
    transData:{},
    personalizationDateFormat:'',
    oooValue: false,
    showchatfooter: false,
    EmployeePersonalizationPhone_Allow_CountryCd:'',
    ErrorMessage :''
    
};
export const personalisation = createSlice({
  name: 'employeepersonalisation',
  initialState: initialState,
  reducers: {
     
      setUserPersonalisationDeatils: (state,action: PayloadAction<any>) => { 
        // state.EmployeeV_Nbr=action.payload.EmployeeV_Nbr
        // state.Id =action.payload.Id
        state.timeZone=action.payload.timeZone
        state.language=action.payload.language
        state.locale=action.payload.locale
        state.personalisationId=action.payload.personalisationId
        state.callType=action.payload.callType
        state.notificationType=action.payload.notificationType
        state.bottomNavBarFlag=action.payload.bottomNavBarFlag
        state.campaignName=action.payload.campaignName
        state.EmployeePermissionCountry_Cd=action.payload.EmployeePermissionCountry_Cd
        state.EmployeePersonalizationPhone_Allow_CountryCd = action.payload.EmployeePersonalizationPhone_Allow_CountryCd
        const QRCODECOUNTRYLIST= 
        config.NO_QRCODE_COUNTRY_LIST.split(",").map((code: string) => ({
          country: code,
        }))
        for(let i = 0; i < QRCODECOUNTRYLIST.length; i++) {
          if(QRCODECOUNTRYLIST[i]?.country == action.payload.EmployeePermissionCountry_Cd){
            state.showchatfooter=true
          }
        }
      },
      
      setUserCallType: (state,action: PayloadAction<any>)=>{
        state.showCallType=action.payload
      },
      setUserTerritoryId: (state,action: PayloadAction<any>)=>{
        state.EmployeeTerritoryId=action.payload
      },
      setErrorMessage :(state,action: PayloadAction<any>)=>{
        state.ErrorMessage=action.payload
      },

      setShowSuccessSnackBar: (state,action: PayloadAction<any>)=>{
        state.showSuccessSnackBar=action.payload
      },

      setShowErrorSnackBar: (state,action: PayloadAction<any>)=>{
        state.showErrorSnackBar=action.payload
      },

      setOptOutKeywords: (state,action: PayloadAction<any>)=>{
        state.optOutKeywords=action.payload
      },

      setOptInKeywords: (state,action: PayloadAction<any>)=>{
        state.optInKeywords=action.payload
      },
      setTraslation: (state,action: PayloadAction<any>)=>{
        state.transData=action.payload
      },
      setUserPersonalizationDateFormat: (state,action: PayloadAction<any>)=>{
        state.personalizationDateFormat=action.payload
      },
      setOooValue: (state,action: PayloadAction<any>)=>{
        state.oooValue=action.payload
      },
    }
});

// this is for dispatch
export const { setUserPersonalisationDeatils,setUserCallType,setErrorMessage,setShowSuccessSnackBar,setUserTerritoryId,setShowErrorSnackBar,setOptOutKeywords,setOptInKeywords,setTraslation,setUserPersonalizationDateFormat,setOooValue} = personalisation.actions;

// this is for configureStore
export default personalisation.reducer;