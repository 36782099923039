import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useGetConversationsQuery } from "../../utils/ApiSlice";
import "./ChatList.css";
// import { useNavigate } from 'react-router-dom';
import config from "../../env.json";
import SearchInput from "../../layouts/searchInput/SearchInput";
import Pusher from "pusher-js";
import NewChatModal from "../../components/chat/NewChatModal";
import { formatDateIntoTodayYesterdayFormat } from "../../utils/Date";
import { useSelector, useDispatch } from "react-redux";
import { getAllConversationsApi } from "../../apis/chat/chatApi";
import {
  setBannerG,
  setChatDetails,
  setChatType,
  setRefreshChatComponent,
  setRefreshChatList,
  setNewMsgReceived
} from "../../app/slice/chatSlice";
import Spinner from "../../layouts/spinner/Spinner";
import Avatar from "../Avatar/Avatar";
import { useTranslation } from "react-i18next";
import { getMsgReadAt } from "../../apis/chat/messageReadAt";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { Keyboard } from "@capacitor/keyboard";

import { setPage } from "../../app/slice/chatSlice";
import { isAndroid } from "react-device-detect";

export default function ChatList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const camp = useSelector((state: any) => state.personalisation.campaignName);
  const virtualUserId = useSelector((state: any) => state.authApi.Id);
  const sRepRole = useSelector((state: any) => state.chat.repRole);

  const refreshChatListStore = useSelector(
    (state: any) => state.chat.refreshChatList
  );
  const [chats, setChats] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [newChat, setNewChat] = useState(false);

  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [paddingValRight, setPaddingValRight] = useState(0);
  const [newChatComponent, setNewChatComponent] = useState(false);
  const [showAttachLoader, setShowAttachLoader] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const [limit] = useState(15);
  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(500);
  const personalizationDateFormat = useSelector(
    (state: any) => state.personalisation.personalizationDateFormat
  );
  const {
    chatId,
    newMsgReceived
  } = useSelector((state: any) => state.chat);
  useEffect(() => {
    if (props.openNewChat) {
      setNewChatComponent(props.openNewChat);
    }
  }, [props]);

  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardWillShow", (info) => {
        // console.log('keyboard will show with height:', info.keyboardHeight);
        getHeight();
      });

      Keyboard.addListener("keyboardDidShow", (info) => {
        getHeight();
      });

      Keyboard.addListener("keyboardWillHide", () => {
        // console.log('keyboard will hide');
        getHeight();
      });

      Keyboard.addListener("keyboardDidHide", () => {
        // console.log('keyboard did hide');
        getHeight();
        console.log("---Keyboard height---");
      });
    }

    // getConversation(1,limit,search);
    getHeight();
    window.addEventListener("resize", () => {
      setTimeout(() => {
        getHeight();
      }, 0);
    });
  }, []);
  useEffect(() => {
    if (!initialLoad){
       getConversation(1, limit, search);
      }
  }, [sRepRole]);

  const getHeight = () => {
    console.log("get height called");
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingright);
    console.log(window.visualViewport?.offsetTop);
    const height = window.visualViewport?.height
      ? window.visualViewport?.height
      : window.innerHeight;
    console.log(height);
    setTimeout(() => {
      setInfiniteScrollHeight(
        height -
          (doc.getElementById("MobHeader")?.offsetHeight +
            document.getElementById("headerVal")?.offsetHeight +
            doc.getElementById("search")?.offsetHeight +
            doc.getElementById("footer")?.offsetHeight) -
          divpaddingTop -
          divpaddingbottom
      );
    }, 100);
  };

  useEffect(() => {
    getConversation(1, limit, search);
    console.log("get height line 146");
    getHeight();
  }, []);

  useEffect(() => {
    console.log("get height line 151");
    if (!isAndroid) {
      window.visualViewport?.addEventListener("resize", () => {
        setTimeout(() => {
          getHeight();
        }, 0);
      });
      window.visualViewport?.addEventListener("orientationchange", () => {
        setTimeout(() => {
          getHeight();
        }, 0);
      });
    } else {
      window.addEventListener("resize", () => {
        setTimeout(() => {
          getHeight();
        }, 0);
      });
      window.addEventListener("orientationchange", () => {
        setTimeout(() => {
          getHeight();
        }, 0);
      });
    }
    return () => {
      console.log("get height line 155");
      if (!isAndroid) {
        window.visualViewport?.addEventListener("resize", () => {
          setTimeout(() => {
            getHeight();
          }, 0);
        });
        window.visualViewport?.addEventListener("orientationchange", () => {
          setTimeout(() => {
            getHeight();
          }, 0);
        });
      } else {
        window.removeEventListener("resize", () => {
          setTimeout(() => {
            getHeight();
          }, 0);
        });
        window.removeEventListener("orientationchange", () => {
          setTimeout(() => {
            getHeight();
          }, 0);
        });
      }
    };
  }, []);
  useEffect(() => {
    if (refreshChatListStore) {
      getConversation(1, limit, search);
    }
  }, [refreshChatListStore]);
  useEffect(() => {
    if (search?.length != 1 && !initialLoad) {
      setChats([]);
      getConversation(1, limit, search);
    }
  }, [search]);

  const handleNewChat = () => {
    // let camp={global.Camp}
    console.log(
      "gc gam check 1 ",
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT,
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    );
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    )
      window.location.href = "/HomeHosp";
    else window.location.href = "/Home";

    // console.log("gam camp ",camp);
  };





  const getConversation = (page, limit, search) => {
    // if (sRepRole !== "") {
    // setShowAttachLoader(true)
    setHasMore(true);
    let UnknFlwIsSameRepNbr = config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER;
    let Role = sRepRole === "sRep" ? "" : sRepRole;

    getAllConversationsApi(
      {
        page,
        limit,
        search,
        UnknFlwIsSameRepNbr,
        Role,
      },
      (res) => {
        if (page === 1 ) {
          setChats(res);
        } else {
          res = res.sort(
            (a: any, b: any) =>
              new Date(b["Recipient Msg End"]).getTime() -
              new Date(a["Recipient Msg End"]).getTime()
          );
          setChats(chats.concat(res));
        }
        dispatch(setRefreshChatList(false));
        if (res?.length == 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setTimeout(() => {
          setInitialLoad(false);
        }, 1000);
        setShowAttachLoader(false);
      }
    );
    // }
  };

  // function truncate(source, size) {
  //   return source.length > size ? source.slice(0, size - 1) + "…" : source;
  // }

  useEffect(() => {
    if (virtualUserId) chatListSubscriber();
  }, [virtualUserId]);

  const chatListSubscriber = () => {
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    let x = newMsgReceived
    const channel = pusher.subscribe("chatdetail");
    channel.bind("RecipientId" + virtualUserId, (data) => {
      dispatch(setRefreshChatList(true));
      dispatch(setRefreshChatComponent(true));
      dispatch(setNewMsgReceived(data.Body))
    });

    return () => {
      pusher.unsubscribe("chatdetail");
    };
  };
  const messageReadAt = (item) => {
    let payload: any = {
      prescOrAssocId: item.PresOrAssocId,
      prescOrAssocType: item.PresOrAssocIdType,
    };
    console.log(payload);
    // getMsgReadAt(payload)
    getMsgReadAt(payload, (res) => {
      console.log(res);
    });
    
  };
  const showChatPage = (item: any, index: any) => {
    console.log(index);
    let id = item.PresOrAssocId;
    let name: any = item.PresOrAssocName;
    let isPrescriber: boolean = item.PresOrAssocIdType == "PRESCR";
    let CWCmessage = "";
    if (item.LastIncomingMsgDtTm) {
      let lastDt: any = new Date(item.LastIncomingMsgDtTm);
      let t: any = Math.abs((new Date() as any) - lastDt);
      let maxExpiry: any = config.MESSAGE_TIMER_WA_OPTIN;
      let m = Math.floor(t / 1000 / 60);
      if (m >= Number(maxExpiry)){
        CWCmessage = "Conversation window closed, please send approved template first"
      }
    }
    let banner: string = item.ActiveFlg=="N" ? "Contact has been deleted": item.TerritoryID==0 ? "Territory/HCP mismatch" : item.PhoneNumber?.length==0 ? "Assign number to reply" : item.OptInStatus=="N" ? "Recipient Opted Out" : CWCmessage.length>0 ? CWCmessage : ""

    messageReadAt(item);
    console.log(item.PresOrAssocId);

    chats[index].CountOfMsgNotRead = 0;
    setChats(chats);

    let chatListData = chats?.map(
      (x) => x["PresOrAssocId"] === item.PresOrAssocId
    );
    console.log(chatListData);
    dispatch(setChatType(item.PresOrAssocIdType));
    dispatch(
      setChatDetails({
        chatId: id,
        chatName: name,
        chatIsPrescriber: isPrescriber,
        showChat: true,
      })
    );
      props.passBanner({banner: banner, id: id})

  };

  const getItemType = (item, outOrnot) => {
    if (outOrnot) {
      if (item == "UNKN") {
        return "Unknown";
      }
      if (item === "PRESCR") {
        return "prescriber";
      }
      if (item === "ASSPRS") {
        return "prescriberassociates";
      }
    }
    return "";
  };

  const containsUppercase = (str: string) => {
    return str === str?.toUpperCase();
  };
  const getItemName = (item: any) => {
    let checkUppercaseStr = containsUppercase(item["PresOrAssocName"]);
    if (item.PresOrAssocIdType == "UNKN")
      return getFormatedPhoneNumber(item["PresOrAssocName"]);
    else if (checkUppercaseStr) {
      return <span>{getFormatedPhoneNumber(item["PresOrAssocName"])}</span>;
    } else
      return item["PresOrAssocName"] ? (
        item["PresOrAssocName"]?.length > 14 ? (
          <span>
            {getFormatedPhoneNumber(item["PresOrAssocName"]).substring(0, 14)}
            ...
          </span>
        ) : (
          <span>
            {getFormatedPhoneNumber(item["PresOrAssocName"]).substring(0, 14)}
          </span>
        )
      ) : (
        <span>Unknown</span>
      );
  };
  return (
    <div className="main-content bg-gray p-0 col-xl-3 chatListDiv">
      {newChatComponent ? (
        <NewChatModal
          show={newChatComponent}
          handleCloseParent={(e) => {
            if (e) {
              getConversation(1, limit, search);
            }
            props.handleNewChatModal(false);
            setNewChatComponent(false);
          }}
        />
      ) : null}

      <div className="searchHeader row--three" id="search">
        <div className="content__header__searchbox chatListPage">
          <div className="content__header__searchbox">
            <SearchInput
              id={"allContactSearch"}
              classes={"form-control font-md search-chats"}
              placeholder={t("Search Chats")}
              onClicked={() => {}}
              onChangeValue={(e) => {
                setSearch(e);
              }}
            />
          </div>
          {config.REACT_APP_SHOW_NEW_CHAT_ICON == "Y" ? (
            <Tooltip title={t("New Chat")} arrow>
              <i
                className="far fa-edit create_newchat notMobile"
                //   @click="openHomeModal"
                id="CreateNewChat"
                onClick={() => {
                  setNewChatComponent(true);
                }}
              ></i>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {/* <div > */}
      <div
        className="chatlistbackground"
        id="scrollableDiv"
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <InfiniteScroll
          dataLength={chats?.length}
          next={() => {
            setPPage(page + 1);
            getConversation(page + 1, limit, search);
          }}
          hasMore={hasMore}
          loader={<Spinner />}
          height={infiniteScrollHeight}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: "lighter",
              }}
            >
              {search?.length > 0 && chats?.length == 0 ? (
                <span>{t("No chat found")}</span>
              ) : null}
              {chats?.length == 0 &&
              search?.length == 0 &&
              config.REACT_APP_SHOW_NEW_CHAT_ICON == "Y" ? (
                <span>
                  <span className="notMobile">
                    {t("Click the")} <i className="far fa-edit"></i>{" "}
                    {t("button to start sending messages")}
                  </span>
                  <span className="onlyMobile">
                    {t('Click the "+" button to start sending messages')}
                  </span>
                </span>
              ) : null}
            </p>
          }
        >
          {showAttachLoader && initialLoad && (
            <span className="rotatingloader"></span>
          )}
          <ul>
            {chats?.map((item: any, index: any) => (
              <li
                className="chatList-div"
                key={
                  item["PresOrAssocId"] +
                  item["PresOrAssocIdType"] +
                  item["RecipientMsgEnd"]
                }
                id={item["PresOrAssocId"]}
                onClick={ async () => {
                await showChatPage(item, index);
                  dispatch(setPage(window.location.pathname));
                  props.setListItemClicked();
                }}
              >
                <div className="chatListItem-div">
                  <div className="d-flex">
                    <div className="avatar-div chatListName">
                      <Avatar
                        imageType={getItemType(item["PresOrAssocIdType"], true)}
                        avatarHeight="2.8rem"
                        avatarWidth="2.8rem"
                        presOrAssoc={item["PresOrAssocName"]}
                      />
                    </div>
                    <div className="chatListDetails">
                      <h4 className="chatListDetailsName">
                        <span>{getItemName(item)}</span>
                      </h4>
                      {item["RecipientMsgBody"] ? (
                        <div className="ChatListMsg">
                          {item["RecipientMsgBody"]}
                        </div>
                      ) : (
                        <div className="ChatListMsg">{t("Attachment")}</div>
                      )}
                    </div>
                  </div>

                  <div className="chatListDate">
                    <div className="msgDate">
                      {t(
                        formatDateIntoTodayYesterdayFormat(
                          item["RecipientMsgEnd"],
                          personalizationDateFormat
                        )
                      )}
                    </div>
                    <div className="badge-container">
                      {item.CountOfMsgNotRead == "0" ? (
                        <span>&nbsp;</span>
                      ) : (
                        <span
                          className="badge"
                          style={{ visibility: "visible" }}
                        >
                          {item.CountOfMsgNotRead}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </InfiniteScroll>
        {config.REACT_APP_SHOW_NEW_CHAT_ICON == "Y" ? (
          <div
            className="floating onlyMobile"
            style={{
              paddingBottom: paddingValBottom,
              paddingRight: paddingValRight,
            }}
          >
            <Tooltip title={t("New Chat")} arrow>
              <div className="floating-button-chatlist">
                <i
                  className="fas fa-comment-medical create_newchat medical-icon"
                  id="CreateNewChat"
                  onClick={() => {
                    handleNewChat();
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  );
}
