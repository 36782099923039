import { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDebounce } from "usehooks-ts";
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import DropdownInupt from "../shared/Dropdown";
import config from "../../env.json";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import { sendMessageApi } from "../../apis/chat/messageApi";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";

import { Tooltip } from "@mui/material";
import {
  addNewContact,
  checkNumberExist,
  contactDetails,
  editContact,
} from "../../apis/contacts/contactsApi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,
  setErrorMessage
} from "../../app/slice/personalisationSlice";
import "./NewChatModal.css";
import GenericButton from "../../layouts/button/Button";
import {
  setChatDetails,
  setRefreshChatComponent,
  setRefreshChatList,
  setChatType,
} from "../../app/slice/chatSlice";
import { useTranslation } from "react-i18next";
import LogoutModal from "../logoutModal/LogoutModal";
import { getAOCStatus, getSRFQuantity } from "../../apis/srfApi/srfApi";
import {
  setAOCStatus,
  setRepQuantity,
  setPresQuantity,
} from "../../app/slice/srfSlice";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { isValid } from "../../utils/CheckValidString";
import getErrorMessage from "../.././utils/getErrorCodeFromJsonFile";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
let primcolor =
  document.documentElement.style.getPropertyValue("--primary-color");
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: primcolor
    ? document.documentElement.style.getPropertyValue("--primary-color")
    : "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor
      ? document.documentElement.style.getPropertyValue("--primary-color")
      : "var(--primary-color)",
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function NewChatModal({ show, handleCloseParent }) {
  const dispatch = useDispatch();
  const messageChannelType = useSelector(
    (state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType
  );
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState({ Id: "" });
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [formType, setFormType] = useState(true);

  const [templateValue, setTemplateValue] = useState<any>({});
  const [templateId, setTemplateId] = useState(0);
  const [templateText, setTemplateText] = useState("");
  const [originalTemplateText, setOriginalTemplateText] = useState("");
  const [isSMS, setIsSms] = useState(true); // if true then whatsapp or else message
  const [disableCampaignInput, setDisableCampaignInput] = useState(true);
  const [disableWaveInput, setDisableWaveInput] = useState(true);
  const [disableSMSInput, setDisableSMSInput] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [campaign, setCampaign] = useState([]);
  const [activeCampaign, setActiveCampagign] = useState<any>(null); //userId
  const [template, setTemplate] = useState<any>([]);
  const [othersData, setOthersData] = useState([]);
  const [othersDatahidden, setOthersDataHidden] = useState(true);
  const [selectedOthers, setSelecteedOthers] = useState<any>({});
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [assets, setAssets] = useState([]);
  const [disableBrandInput, setDisableBrandInput] = useState(true);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [associated, setAssociated] = useState<any>({});
  const [isForm, setIsForm] = useState(false);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectForm] = useState<any>({});
  const [optedOut, setOptedOut] = useState(false);
  const [formsValues, setFormValues] = useState<any>([]);
  const [allCampaign, setAllCampaign] = useState<any>([]);
  const [allWaves, setAllWaves] = useState<any>([]);
  const refForm = useRef<any>(null);
  const [activeWave, setActiveWave] = useState<any>(null);
  const [activeProduct, setActiveProduct] = useState<any>(null);
  const [disableTemplate, setDisableTemplate] = useState<any>(true);
  const [initialPhone, setInitialPhone] = useState<any>(true);
  const [showOnce, setShowOnce] = useState(false);
  const [overrideModal, setOverrideModal] = useState(false);
  const [formDataGlobal, setFormDataGlobal] = useState<any>(null);
  const [repQuantity, setRepQuantityLocal] = useState<any>([]);
  const [presQauntity, setPresQauantityLocal] = useState<any>([]);
  const [aoc, setAoc] = useState<any>([]);
  const [tempProductId, setTempProductId] = useState<any>(0);
  const [repQuantityError, setRepQuantityError] = useState(false);
  const [presQuantityError, setPresQuantityError] = useState(false);
  const [aocError, setAocError] = useState(false);
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState("");
  const [discolor, setDiscolor] = useState(true);
  const [asscPresInputDisabled,setAsscPresInputDisabled]= useState(true);
  const [error,setError]=useState(false);
  const [isValidString,setIsValidString]=useState(false);
  const [errorData, setErrorData] = useState('');
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);
  // const [mutliAssociatePrescriberCheck, setMutliAssociatePrescriberCheck] =
  //   useState<any>(true);
  // const [showOptOutErrMsg, setShowOptOutErrMsg] = useState<any>(false);
  // const [clearDataOnDelete, setClearDataOnDelete] = useState(false)
  // const [showPhnErrMsg, setShowPhnErrMsg] = useState<any>(false);
  // const [prescriberName,setPrescriberName]=useState<any>('')

  // const {
  //   data: post,
  //   isFetching,
  //   isSuccess,
  // } = useGetPrescribersQuery({
  //   page: 0,
  //   pageSize: 5,
  //   search: assoPrescName,
  // });
  // const pullTemplateData = (data) => {
  //    console.log(data.length); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  //   // { ? : ''}
  //   if (data.length > 0) {
  //     setTemplateValue(true);
  //   } else {
  //     return null;
  //   }
  // };

  // const isValid = (inputString:string)=>{

  //     let pattern = /^(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9\s][a-zA-Z0-9]?\s*$/;
  //     let result = pattern.test(inputString);    

  //     return result;
  // }
  useEffect(() => {
    setErrorData("*"+t(getErrorMessage('NameError')))
    setCountryList();
  },[]);
  useEffect(() => {
    getSRFQuantity((res: any) => {
      console.log(res);
      if (res) {
        setRepQuantityLocal(res.employeeQnt ? res.employeeQnt : []);
        setPresQauantityLocal(res.prescriberQnt ? res.prescriberQnt : []);
        dispatch(setRepQuantity(res.employeeQnt ? res.employeeQnt : []));
        dispatch(setPresQuantity(res.prescriberQnt ? res.prescriberQnt : []));
      }
    });
    getAOCStatus((res: any) => {
      if (res) {
        setAoc(res);
        dispatch(setAOCStatus(res));
      }
    });
    getTemplatesFromCampign();
  }, []);
  const getFormStatus = (value: any) => {
    let rep = repQuantity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    let pres = presQauntity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let pq = pres.length > 0 ? pres[0].quantity : 0;
    let n = aoc.filter(
      (x) =>
        x.product_id == tempProductId &&
        x.prescriber_id == assoPresc.Id &&
        x.aoc_status == "pending"
    );
    let nc = n.length > 0 ? n[0].aoc_status : "unknown";

    if (value > rq && rq != 0) {
      setRepQuantityError(true);
    } else {
      setRepQuantityError(false);
    }
    if (value > pq && pq != 0) {
      setPresQuantityError(true);
    } else {
      setPresQuantityError(false);
    }
    if (nc == "pending") setAocError(true);
    else setAocError(false);

    if ((value > rq && rq != 0) || (value > pq && pq != 0) || nc == "pending")
      setDisabled(true);
    else setDisabled(false);
  };
  let countryCode = useSelector((state:any)=> state.personalisation.EmployeePersonalizationPhone_Allow_CountryCd)  
  const setCountryList = () => {
    let userCountryList:Array<any>= countryCode?.split(",")?.map((code: string) =>code?.toLowerCase()) 
    setCountries(userCountryList)
  };
  const getRepLimit = () => {
    let rep = repQuantity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    
    return rq;
  };
  const getPresLimit = () => {
    let rep = presQauntity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };

  const getContactsData = (value) => {
    setContactLoading(true);

    if (value.length >= 2) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?ContactType=A&Page=" +
            0 +
            "&PageSize=" +
            pageSize +
            "&Search=" +
            encodeURIComponent(value),
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setOthersData(
            res.data.data.filter((x: any) => x.Is_Prescriber == "N")
          );
          setPost(res.data.data.filter((x: any) => x.Is_Prescriber == "Y"));
          setContactLoading(false);
        });
    } else {
      setOthersDataHidden(true);
      setOthersData([]);
      setPost([]);
      setHiddenPresList(true);
      setContactLoading(false);
    }
  };
  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () {}, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    setOthersDataHidden(false);
    getContactsData(debouncedValue);
  }, [debouncedValue]);
  const setPrescriber = (x: any) => {
    console.log(x);
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setOthersPhone(getNumberFromItem(x));
    setHiddenPresList(true);
    setDisabled(false);
    brandInputdisable(x.AcctFull_Nm.length > 0, x.AcctPh_Nbr.length > 0, true);
    setOptedOut(x.All_Ph_Cont_Flags === "N, N, N");
    if (
      x.AcctFull_Nm !== "" ||
      x.AcctFull_Nm !== null ||
      x.AcctFull_Nm !== undefined
    ) {
      setDiscolor(false);
    }
  };
  const getNumberFromItem = (item) => {
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    } else return "";
  };

  const handleOverrideMsg = (accType, accName) => {
    if (config.REACT_APP_PH_OVERRIDE_MSG) {
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
      let type = accType === "N" ? t("Others") : t("Prescriber");
      let name = accName;
      if (type) {
        msg = msg.replace("TYPE", type);
      } else {
        msg = msg.replace("TYPE -", "");
      }
      if (name) {
        msg = msg.replace("NAME", name);
      }
      setOverrideMessage(msg);
    }
  };

  const updateFormValue = (i, value) => {
    let formVal = [...formsValues];
    let item = {
      ...formVal[i],
      Value: value,
    };
    formVal[i] = item;
    setFormValues(formVal);
    updateTemplateText(formVal);
  };

  const falsifyShowOnce = () => {
    setShowOnce(false);
  };
  const updateTemplateText = (formData) => {
    var templateBody = originalTemplateText;
    formData.map((x: any, i: any) => {
      if ("Value" in x) {
        //remove this condition
        var replaceWord = "[" + x.FieldName + "]";
        templateBody = templateBody.replace(replaceWord, x.Value);
      }
    });
    setTemplateText(templateBody);
  };
  async function addContact() {
    let payload = {
      AcctPh_Nbr: othersPhone,
      Prescriber_Id: assoPresc.Id as any,
      AcctFull_Nm: othersName,
      IsPrescriber: false,
    };
    console.log(payload);
    axios
      .post(
        config.REACT_APP_CONTACTS_API_BASE + "/contact",
        {
          AcctPh_Nbr: othersPhone,
          Prescriber_Id: assoPresc.Id as any,
          AcctFull_Nm: othersName,
          IsPrescriber: false,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("login"),
          },
        }
      )
      .then((res) => {
        handleCloseParent(true);
      })
      .catch((err) => {
        handleCloseParent(false);
      });
  }
  const sendToPrescriber = (formData: any) => {
    formData.append("role_id", "0");
    formData.append("recipient_id", assoPresc.Id);
    formData.append("recipient_name", assoPresc["AcctFull_Nm"]);
    formData.append("physcian_id", assoPresc.Id);
    console.log("senddsad ::", templateText);
    sendMessageApi(formData, (res) => {
      dispatch(setChatType("PRESCR"));
      dispatch(
        setChatDetails({
          chatId: assoPresc.Id,
          chatIsPrescriber: true,
          showChat: true,
          chatName: assoPresc["AcctFull_Nm"],
        })
      );

      setLoading(false);
      reSetFormInputs(true);
      handleCloseParent(true);
      dispatch(setRefreshChatList(true));
      dispatch(setRefreshChatComponent(true));
      if (res) dispatch(setShowSuccessSnackBar(true));
      else dispatch(setShowSuccessSnackBar(false));
    });
  };
  const sendToOthers = (formData: any) => {};
  const sendMessageForPresOrOthers = (formData) => {
    
    if (formType) {
      //prescriber
      setLoading(true);
      if (getNumberFromItem(assoPresc) !== othersPhone) {
        let phn: any = parsePhoneNumber(othersPhone);
        let payload: any = {
          AcctPh_Nbr: phn.nationalNumber,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Id: assoPresc.Id,
        };
        checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          if (res) {
            handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
            setFormDataGlobal(formData);
            setOverrideModal(true);
          } else {
            editContact(payload, (res: any) => {});
            sendToPrescriber(formData);
          }
        });
      } else {
        sendToPrescriber(formData);
      }
    } else {
      //others
      let isValidStringEntered = isValid(othersName);

      let ContactName = othersName.replace(/\s+/g, " ").trim();

      if (ContactName && isValidStringEntered) {
        setLoading(true);

        if (!selectedOthers.Id) {
          let phn: any = parsePhoneNumber(othersPhone);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            Prescriber_Id: [associated.Id],
            AcctFull_Nm: ContactName,
            IntlCallPfx_Nbr: phn.countryCallingCode,
          };

          checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
            if (res) {
              handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
              setFormDataGlobal(formData);
              setOverrideModal(true);
            } else {
              addNewContact(payload, (res: any) => {
                if (res) {
                  formData.append("recipient_id", res.Id);
                  formData.append("recipient_name", ContactName);
                  formData.append("role_id", "1");
                  formData.append(
                    "association_id",
                    res.Prescribers.filter((x) => x.Id == associated.Id)[0]
                      .Association_Id
                  );
                  formData.append("physcian_id", associated.Id);
                  sendMessageApi(formData, (resp: any) => {
                    if (resp) {
                      dispatch(setChatType("ASSPRS"));

                      dispatch(
                        setChatDetails({
                          chatId: res.Id,
                          chatIsPrescriber: false,
                          showChat: true,
                          chatName: ContactName,
                        })
                      );
                      setLoading(false);

                      reSetFormInputs(true);
                      handleCloseParent(true);
                      dispatch(setRefreshChatList(true));
                      dispatch(setRefreshChatComponent(true));

                      dispatch(setShowSuccessSnackBar(true));
                    } else dispatch(setShowSuccessSnackBar(false));
                  });
                } else {
                }
              });
            }
          });
        } else {
          let phn: any = parsePhoneNumber(othersPhone);
          console.log(phn);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            AcctFull_Nm: ContactName,
            Prescriber_Id: [associated.Id],
            Id: othersData.map((x: any) => x.Id),
            IntlCallPfx_Nbr: phn.countryCallingCode,
          };
          formData.append("recipient_id", selectedOthers.Id);
          formData.append("recipient_name", ContactName);
          formData.append("role_id", "1");
          formData.append("association_id", associated.Association_Id);
          formData.append("physcian_id", associated.Id);

          if (othersPhone != getNumberFromItem(selectedOthers)) {
            checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
              if (res) {
                
                handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
                setFormDataGlobal(formData);
                setOverrideModal(true);
              } else {
                
                addNewContact(payload, (res: any) => {

                  if (res) {
                    formData.append("recipient_id", res.Id);
                    formData.append("recipient_name", ContactName);
                    formData.append("role_id", "1");
                    formData.append(
                      "association_id",
                      res.Prescribers.filter((x) => x.Id == associated.Id)[0]
                        .Association_Id
                    );
                    formData.append("physcian_id", associated.Id);
                    sendMessageApi(formData, (resp: any) => {
                      //console.log(res)
                      if (resp) {
                        dispatch(setChatType("ASSPRS"));
                        
                        dispatch(
                          setChatDetails({
                            chatId: res.Id,
                            chatIsPrescriber: false,
                            showChat: true,
                            chatName: ContactName,
                          })
                        );
                        setLoading(false);

                        reSetFormInputs(true);
                        handleCloseParent(true);
                        dispatch(setRefreshChatList(true));
                        dispatch(setRefreshChatComponent(true));

                        dispatch(setShowSuccessSnackBar(true));
                      } else dispatch(setShowSuccessSnackBar(false));
                    });
                  } else {
                    dispatch(setShowErrorSnackBar(true));
                    dispatch(setErrorMessage("An error has occurred"))
                  }
                });
                sendMessageApi(formData, (res) => {
                  dispatch(setChatType("ASSPRS"));
                  dispatch(
                    setChatDetails({
                      chatId: selectedOthers.Id,
                      chatIsPrescriber: false,
                      showChat: true,
                      chatName: ContactName,
                    })
                  );
                  setLoading(false);
                  reSetFormInputs(true);
                  handleCloseParent(true);
                  dispatch(setRefreshChatList(true));
                  dispatch(setRefreshChatComponent(true));
                  if (res) dispatch(setShowSuccessSnackBar(true));
                  else dispatch(setShowSuccessSnackBar(false));
                });
              }
            });
          } else {
            sendMessageApi(formData, (res) => {
              dispatch(setChatType("ASSPRS"));
              dispatch(
                setChatDetails({
                  chatId: selectedOthers.Id,
                  chatIsPrescriber: false,
                  showChat: true,
                  chatName: ContactName,
                })
              );
              setLoading(false);
              reSetFormInputs(true);
              handleCloseParent(true);
              dispatch(setRefreshChatList(true));
              dispatch(setRefreshChatComponent(true));
              if (res) dispatch(setShowSuccessSnackBar(true));
              else dispatch(setShowSuccessSnackBar(false));
            });
          }
        }
      } else {
        setIsValidString(!isValidStringEntered);
        setSelecteedOthers({});
        setOthersPhone("");
        setAssoPrescName("");
        setAsscPresInputDisabled(true);
        setDiscolor(true);
        brandInputdisable(othersName.length > 0, othersPhone.length > 0, false);

        return;
      }
    }
  };
  const sendMessage = () => {
    let formData = new FormData();

    
    formData.append("recipient_phone", othersPhone);
    formData.append("Campaign_ID", "1000");
    formData.append("wave_id", templateValue.mainWaveId);
    formData.append("product_id", templateValue.productId);

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    

    if (isForm) {
      let fdata: any = new FormData(refForm.current);
      let details: any = [];
      for (const [key, value] of fdata) {
        details.push({
          idOfFieldToAskFromEmp: JSON.parse(key),
          valueOfFieldToAskFromEmp: value,
        });
      }

      let formPay = {
        formId: selectedForm.FormId,
        fieldDetails: details,
        recipientId: assoPresc.Id,
        recipientType: formType ? "PRESCR" : "ASSPRS",
      };
      console.log(JSON.stringify(formPay));
      axios
        .post(config.REACT_APP_FORMS_API_BASE + "/formRequest", formPay)
        .then((res: any) => {
          console.log(res.data);
          let temptest = templateText.toString();
          if (temptest.indexOf("shorturl") >= 0)
            formData.append(
              "template",
              templateText.toString().replace("[shorturl_1]", res.data.FormUrl)
            );
          else
            formData.append("template", templateText + " " + res.data.FormUrl);
          formData.append("urls", res.data.FormUrl);
          sendMessageForPresOrOthers(formData);
          let qtPayload = {
            quantityUsed: details[0].valueOfFieldToAskFromEmp,
            prescriberId: assoPresc.Id,
            productId: tempProductId,
          };
          axios
            .post(
              config.REACT_APP_CAMPAIGN_API_BASE + "/srf/updateQauntity",
              qtPayload
            )
            .then((res: any) => {});
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      
      let temp = templateText.toString();
      if (!templateValue.FormId && templateValue.FieldDetails.length > 0) {
        templateValue.FieldDetails.map((x: any) => {
          temp = temp.replace("[" + x.FieldName + "]", "");
          return x;
        });
      }

      formData.append("template_id", templateId.toString());
      formData.append("template", temp.toString());
      sendMessageForPresOrOthers(formData);
    }

  
  
    //for prescriber

    // formData.append("recipient_name", data['Sender Name']);
    // formData.append("recipient_phone", data.recipient_phone);
    //  formData.append("physcian_id", data.physcian_id);
    //  formData.append("role_id", data.type);
    //  formData.append("template", data.template);
    //  formData.append("Campaign_ID", 1000);
    //  formData.append("template_id");
    //  formData.append('wave_id',data.)

    

    // formData.append("file", this.files);
    //  for (var i = 0; i < files.length; i++) {
    //    let file = files[i];
    //    formData.append("file", file);
    //  }

    // axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //   axios.post("http://localhost:8000/api/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{
    //   console.log(res)
    //   setLoading(false);
    //   reSetFormInputs(true);
    //   handleCloseParent(true)
    // }).catch(res=>setLoading(false))
  };
  const setChat = (post: any) => {
    dispatch(
      setChatDetails({
        chatId: post.Id,
        chatIsPrescriber: post.Is_Prescriber == "Y",
        showChat: true,
        chatName: post.AcctFull_Nm,
      })
    );
    window.location.href = "/chat";
  };

  const TemplatesForms = [
    {
      formId: 1000,
      formName: "MIR",
      fieldName: "Question",
      fieldType: "string",
      File_Nm: "MIR Form",
      waveId: "1000",
      form: true,
      productId: "1000",
      campaginId: "1000",
      Template_Val:
        "As per your request. I have created the MIR form. Please review and submit the form here: [shorturl_1]",
      isForm: true,
      Id: 7000,
    },
  ];

  const getTemplatesFromCampign = () => {
    getCampaignsData({ page: 0, pageSize: 10 }, (res) => {
      if (res.length > 0) {
        let tempAssets: any = [];
        let campaigns: any = [];
        let waves: any = [];
        let products: any = [];

        res.map((x: any) => {
          campaigns.push(x);
          x.type = "Campaign";
          x.idName = ["type", "CampaignName", "CamapignId"];
          x.labelName = "CampaignName";
          x.childName = "WaveDetails";
          x.WaveDetails.map((y: any) => {
            waves.push(y);
            y.type = "Wave";
            y.idName = ["type", "WaveName", "WaveId"];
            y.labelName = "WaveName";
            y.childName = "ProductDetails";
            y.ProductDetails.map((z: any) => {
              z.waveId = y.WaveId;
              products.push(z);
              z.type = "Product";
              z.idName = ["type", "ProductName", "ProductId"];
              z.labelName = "ProductName";
              z.childName = "TemplateDetails";
              z.TemplateDetails = z.TemplateDetails.sort(function (
                a: any,
                b: any
              ) {
                return a.SortOrder_Nbr - b.SortOrder_Nbr;
              });
              z.TemplateDetails.map((p: any) => {
                p.type = "Template";
                p.productId = z.ProductId;
                p.mainWaveId =
                  p.AssetDetails.length == 0
                    ? p.CampaignWaveId
                    : p.AssetDetails[0].CampaignWaveId;
                if (p.FormId) {
                  p.TemplateName = p.FormName;
                }
                p.idName = ["type", "TemplateName", "TemplateId"];
                p.labelName = "TemplateName";
                p.childName = "AssetDetails";
                tempAssets = tempAssets.concat(p.AssetDetails);
                p.AssetDetails.map((o: any) => {
                  o.mainItem = true;
                  o.type = "Asset";
                  o.idName = ["type", "AssetFile_Nm", "AssetId"];
                  o.labelName = "AssetFile_Nm";
                  return o;
                });
                return p;
              });
              return z;
            });
            return y;
          });
          return x;
        });

        setAllCampaign(campaigns);
        let activeC: any = window.localStorage.getItem("activeCampaign");
        if (activeC) {
          let index = campaigns.findIndex(
            (x) => x.CamapignId == JSON.parse(activeC).CamapignId
          );
          if (index >= 0) {
            activeC = JSON.parse(activeC);
            setActiveCampagign(campaigns[index]);
          } else {
            activeC = campaigns[0];
            setActiveCampagign(campaigns[0]);
          }
        } else {
          activeC = campaigns[0];
          setActiveCampagign(campaigns[0]);
        }

        
        let tempWaves = activeC.WaveDetails ? activeC.WaveDetails : [];
        setAllWaves(tempWaves);

        let activeW: any = window.localStorage.getItem("activeWave");
        
        if (activeW) {
          let index = tempWaves.findIndex(
            (x) => x.WaveId == JSON.parse(activeW).WaveId
          );
          if (index >= 0) {
            activeW = JSON.parse(activeW);
            setActiveWave(tempWaves[index]);
          } else {
            activeW = tempWaves[0];
            setActiveWave(tempWaves[0]);
          }
        } else {
          activeW = tempWaves[0];
          setActiveWave(tempWaves[0]);
        }

        let tempProducts = activeW.ProductDetails ? activeW.ProductDetails : [];
        
        setCampaign(tempProducts); //campagins is used as products
        let activep: any = window.localStorage.getItem("activeProduct");

        if (activep) {
          let index = tempProducts.findIndex(
            (x) => x.ProductId == JSON.parse(activep).ProductId
          );
          if (index >= 0) {
            activep = JSON.parse(activep);
            setActiveProduct(tempProducts[index]);
          } else {
            activep = tempProducts[0];
            setActiveProduct(tempProducts[0]);
          }
        } else {
          activep = tempProducts[0];
          setActiveProduct(tempProducts[0]);
        }

        let tempTemplates = activep.TemplateDetails
          ? activep.TemplateDetails
          : [];
        setTemplate(tempTemplates);
      }
    });
  };
  const getFormsData = (waveId) => {
    console.log("hi");
    axios
      .get("https://p360zingformsapi.azurewebsites.net/api/formslist/" + waveId)
      .then((res) => {
        console.log(res.data);
        let d = res.data.map((x) => {
          x["File_Nm"] = x.formName + " Form";
          x.waveId = "1000";
          x.form = true;
          x.productId = "1000";
          x.campaginId = "1000";
          x.Template_Val = "";
          x.Category_Cd = "FRM";

          return x;
        });
        var groupBy = function (xs, key) {
          return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };
        let temp = groupBy(d, "formId");
        let data: any = [];
        for (let i in temp) {
          let o: any = temp[i][0];
          o.questions = temp[i];
          data.push(o);
        }
        console.log(data);
        

        setForms(d);
        
      });
  };
  const brandInputdisable = (name, phone, asso_pres) => {
    console.log(name, phone, asso_pres);
    if (formType || !formType) {
      if (name && phone && asso_pres) {
        
        setDisableBrandInput(false);
        setDisableCampaignInput(false);
        setDisableWaveInput(false);
        setDisableTemplate(false);
      } else {
        setDisableBrandInput(true);
        setDisableCampaignInput(true);
        setDisableWaveInput(true);
        setDisableTemplate(true);
      }
    }
  };

  const setPhoneNumber = (e) => {
    
    setInitialPhone(false);
    setError(!isValidPhoneNumber(e));

    if (e) {
      setOthersPhone(e);
      console.log("brand INPUT");
      brandInputdisable(
        assoPrescName.length > 0,
        e.length > 0,
        assoPrescName.length > 0
      );
    } else {
      setOthersPhone("");
      console.log("brand INPUT");
      brandInputdisable(
        assoPrescName.length > 0,
        false,
        assoPrescName.length > 0
      );
    }
    if (e.length > 0) {
      setAsscPresInputDisabled(false);
    } else {
      setAsscPresInputDisabled(true);
    }
  };
  // const smsInputdisable = (name,phone,asso_pres,brand) =>{
  //   if(name && phone && asso_pres && brand){
  //     setDisableSmsInput(true)
  //   } else {
  //     setDisableSmsInput(false)
  //   }
  // }
  const setOthersFromList = (x) => {
    if (x.Is_Prescriber == "N") {
      let RecType = false;
      contactDetails({ Id: x.Id, Is_Prescriber: RecType }, (res) => {
        
        
        setTimeout(() => {
          setDisableWaveInput(false);
          setDiscolor(false);
          setAsscPresInputDisabled(false);
        }, 200);

        if (res) {
          console.log("jjjjjjjjjjjjjj", res);
          setShowOnce(false);
          console.log("lllllll", x);
          setDiscolor(false);
          setOptedOut(res.All_Ph_Cont_Flags === "N, N, N");
          setSelecteedOthers(res);
          setOthersName(res.AcctFull_Nm);
          setOthersDataHidden(true);
          if (res.Prescribers) {
            
            if (res.Prescribers.length === 1) {
              setAssociated(res.Prescribers[0]);
              setPrescriberName(res.Prescribers[0].AcctFull_Nm);
              setAssoPrescName(res.Prescribers[0].AcctFull_Nm);
              setDisabled(res.Prescribers[0].AcctPh_Nbr.length <= 0);
              setDisableTemplate(res.Prescribers[0].AcctPh_Nbr.length <= 0);
            } else {
              brandInputdisable(true, res.AcctPh_Nbr.length > 0, false);
            }
          }
        }
      });
    }

    setOthersPhone(getNumberFromItem(x));
  };

  const disableWaveNTemplate = () => {
    setTimeout(() => {
      setSelecteedOthers({});
      setInitialPhone(true);
      setTemplateId(0);
      setAssoPrescName("");
      setAssoPresc({ Id: "" });
      setHiddenPresList(true);
      setDisabled(true);

      setOthersPhone("");
      setOthersPhone("9999999");
      setOthersPhone("");
      setDisableWaveInput(true);
      setDisableTemplate(true);
      setActiveWave(null);
      setTemplate([]);
      setTemplateValue({});
      setTemplateText("");

      setOriginalTemplateText("");
      setOthersName("");
      setDisableCampaignInput(true);

      setOptedOut(false);
      setAsscPresInputDisabled(true);
      setDiscolor(true);
      setFormValues([]);
      setAssets([]);
      setAssociated({});
    }, 200);
  };

  const reSetFormInputs = (e) => {
    setSelecteedOthers({});
    setInitialPhone(true);
    setFormType(e);
    setTemplateId(0);
    setAssoPrescName("");
    setAssoPresc({ Id: "" });
    setHiddenPresList(true);
    setDisabled(true);
    setOthersPhone("");
    setOthersPhone("9999999");
    setOthersPhone("");
    setDisableBrandInput(true);
    setTemplateText("");

    setOriginalTemplateText("");
    setOthersName("");
    setDisableCampaignInput(true);
    setDisableWaveInput(true);
    setDisableTemplate(true);
    setAssets([]);
    setAssociated({});

    setActiveWave(null);
    setTemplate([]);
    setTemplateValue({});

    setOptedOut(false);
    setAsscPresInputDisabled(true);
    setDiscolor(true);
  };
  const selectbrand = (e) => {
    console.log("inside brand");
    window.localStorage.setItem("activeProduct", JSON.stringify(e));
    setTemplate(e.TemplateDetails ? e.TemplateDetails : []);
    setActiveProduct(e);
    setTemplateText("");
    setTempProductId(e.ProductId);
    setOriginalTemplateText("");
    setTemplateId(0);
    setAssets([]);
    setIsForm(false);
  };

  const selectWave = (e) => {
    setDisableTemplate(true);
    window.localStorage.setItem("activeWave", JSON.stringify(e));
    setActiveWave(e);
    setActiveProduct(null);
    
    setCampaign(e.ProductDetails ? e.ProductDetails : []);
    if (e.ProductDetails.length == 1) {
      selectbrand(e.ProductDetails[0]);
    }
    setTemplateText("");
    setOriginalTemplateText("");
    setTemplateId(0);
    if (disableWaveInput == false) {
      if (allWaves.length == 1) {
        setDisableTemplate(false);
      } else {
        setDisableBrandInput(false);
        setDisableTemplate(false);
      }
    } else {
      setDisableBrandInput(true);
      setDisableTemplate(false);
    }

    setAssets([]);
    setIsForm(false);
  };
  const setPrescriberName = (e) => {
    if (assoPrescName.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const selectCampaign = (e) => {
    setDisableTemplate(true);
    window.localStorage.setItem("activeCampaign", JSON.stringify(e));
    setActiveWave(null);
    setActiveProduct(null);
    setAllWaves(e.WaveDetails ? e.WaveDetails : []);
    if (e.WaveDetails.length == 1) {
      selectWave(e.WaveDetails[0]);
    }
    setTemplateText("");
    setOriginalTemplateText("");
    setTemplateId(0);
    setAssets([]);
    if (disableCampaignInput == false) {
      setDisableWaveInput(false);
      setDisableTemplate(true);
      setDisableBrandInput(true);
    } else {
      setDisableWaveInput(true);
      setDisableTemplate(false);
      setDisableBrandInput(false);
    }
    setIsForm(false);
  };

  const overrideFunction = (e) => {
    let formData: any = formDataGlobal;
    if (e) {
      if (formType) {
        let phn: any = parsePhoneNumber(othersPhone);
        let payload: any = {
          AcctPh_Nbr: phn.nationalNumber,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Id: assoPresc.Id,
        };
        editContact(payload, (res: any) => {});
        sendToPrescriber(formData);
      } else {
        if (!selectedOthers.Id) {
          let phn: any = parsePhoneNumber(othersPhone);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            Prescriber_Id: [associated.Id],
            AcctFull_Nm: othersName,
            IntlCallPfx_Nbr: phn.countryCallingCode,
          };

          addNewContact(payload, (res: any) => {
            if (res) {
              formData.append("recipient_id", res.Id);
              formData.append("recipient_name", othersName);
              formData.append("role_id", "1");
              formData.append(
                "association_id",
                res.Prescribers.filter((x) => x.Id == associated.Id)[0]
                  .Association_Id
              );
              formData.append("physcian_id", associated.Id);
              sendMessageApi(formData, (resp: any) => {
                if (resp) {
                  dispatch(setChatType("ASSPRS"));
                  
                  dispatch(
                    setChatDetails({
                      chatId: res.Id,
                      chatIsPrescriber: false,
                      showChat: true,
                      chatName: othersName,
                    })
                  );
                  setLoading(false);
                  reSetFormInputs(true);
                  handleCloseParent(true);
                  dispatch(setRefreshChatList(true));
                  dispatch(setRefreshChatComponent(true));
                  dispatch(setShowSuccessSnackBar(true));
                } else dispatch(setShowSuccessSnackBar(false));
              });
            } else {
            }
          });
        } else {
          let phn: any = parsePhoneNumber(othersPhone);
          console.log(phn);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            AcctFull_Nm: othersName,
            Prescriber_Id: [associated.Id],
            Id: othersData.map((x: any) => x.Id),
            IntlCallPfx_Nbr: phn.countryCallingCode,
          };
          addNewContact(payload, async (cont) => {
            if (cont) {
              dispatch(setShowSuccessSnackBar(true));
            } else {
              dispatch(setShowErrorSnackBar(true));
              dispatch(setErrorMessage("An error has occurred"))
            }
          });
          sendMessageApi(formData, (res) => {
            dispatch(setChatType("ASSPRS"));
            dispatch(
              setChatDetails({
                chatId: selectedOthers.Id,
                chatIsPrescriber: false,
                showChat: true,
                chatName: othersName,
              })
            );
            setLoading(false);
            reSetFormInputs(true);
            handleCloseParent(true);
            dispatch(setRefreshChatList(true));
            dispatch(setRefreshChatComponent(true));
            if (res) dispatch(setShowSuccessSnackBar(true));
            else dispatch(setShowSuccessSnackBar(false));
          });
        }
      }
    } else {
      setLoading(false);
      setDisabled(false);
    }
  };

  const setNumberLandline = (e) => {
    console.log(e);
    setDisabled(e);
    setDisableTemplate(assoPrescName.length > 0 && e);
    brandInputdisable(assoPrescName.length > 0, !e, assoPrescName.length > 0);
    setIsNumberLandline(e);
  };
  return (
    <div>
      <Modal
        open={show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="new_chat_modal"
      >
        <Fade in={show}>
          <Box sx={style}>
            <div className="modal-header d-flex">
              <h1 className="font-newchat">{t("New Chat")}</h1>
              <span
                onClick={() => {
                  handleCloseParent();
                  reSetFormInputs(true);
                }}
                className="close"
              >
                ×
              </span>
            </div>

            <div className="modal-body new_chat_body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <div className="selecttype-radio-btns">
                  <FormControl className="width100per">
                    <div className="newChatModalBody">
                      <FormLabel id="demo-customized-radios">
                        {t("Select Type")}
                      </FormLabel>
                      {messageChannelType == true &&
                      config.REACT_APP_SHOW_CHANNEL_TYPE_TOGGLE_BTN === "Y" ? (
                        <div className="headerright">
                          <label className="autopilot-switch font-sm me-3">
                            <input
                              checked={isSMS}
                              onChange={(e) => {
                                setIsSms(e.target.checked);
                              }}
                              type="checkbox"
                              className="font-sm"
                              id="msg-wtsappChechedId"
                            />

                            <Tooltip
                              title={
                                isSMS === false
                                  ? t("Message Mode")
                                  : t("WhatsApp Mode")
                              }
                              arrow
                            >
                              <span
                                id="msg-wtsapptoggle"
                                className="b-slider b-round"
                              ></span>
                            </Tooltip>
                          </label>
                        </div>
                      ) : null}
                    </div>

                    <RadioGroup
                      row
                      defaultValue="prescriber"
                      aria-labelledby="demo-customized-radios"
                      name="customized-radios"
                      onClick={(e: any) => {
                        e.target.value === "prescriber"
                          ? reSetFormInputs(true)
                          : reSetFormInputs(false);
                      }}
                    >
                      <FormControlLabel
                        value="prescriber"
                        control={<BpRadio />}
                        label={t("Prescriber")}
                      />
                      <FormControlLabel
                        value="others"
                        control={<BpRadio />}
                        label={t("Others")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="form-fields-div new-chat-prescriber">
                  {formType === true ? (
                    <AssociatedPresList
                      clearDataOnSelect={false}
                      placeholder={t("Search for a Prescriber")}
                      label={t("Prescriber Name") + "*"}
                      styleClass="inputEnable"
                      onChangeInputValue={(e: any) => {
                        setPrescriberName(e);
                        setDisabled(true);
                        if (e.length == 0) {
                        }
                      }}
                      setAssoPresc={(e: any) => {
                        setPrescriber(e);
                        setDisabled(false);
                      }}
                    />
                  ) : (
                    <div className="NewChatModalOthersName">
                      <TextField
                        className="roundborder inputEnable"
                        onChange={(e) => {
                          
                          setShowOnce(true);
                          setIsValidString(false);
                          const result = e.target.value.replace(
                            /[^A-Za-z0-9 _]/gi,
                            ""
                          );

                          if (result.length == 0) {
                            console.log("INSIDE");

                            setSelecteedOthers({});
                            setOthersPhone("");
                            setAssoPrescName("");
                            setAsscPresInputDisabled(true);
                            setDiscolor(true);

                            disableWaveNTemplate();
                            setContactLoading(false);

                            setOthersName(result);
                          } else if (result.length > 0 && result) {
                            if (result.length == 1) {
                              setDiscolor(true);
                              setAsscPresInputDisabled(true);
                            } else {
                              if (result.trim().length === 0) {
                                setDiscolor(true);
                                setAsscPresInputDisabled(true);
                              } else {
                                setDiscolor(false);
                                setAsscPresInputDisabled(false);
                              }
                            }

                            setOthersName(result);
                          }
                        }}
                        value={othersName}
                        label={t("Recipient Name") + "*"}
                        placeholder={t("Enter the recipient's name")}
                        color="secondary"
                        focused
                        autoComplete="off"
                        InputProps={
                          contactLoading
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CircularProgress
                                      color={"inherit"}
                                      size={"1rem"}
                                    />
                                  </InputAdornment>
                                ),
                              }
                            : undefined
                        }
                        inputProps={{
                          maxLength: config.REACT_APP_CONTACT_NAME_MAXLENGTH,
                        }}
                      />

                      {isValidString && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            margin: "0",
                          }}
                        >
                          {errorData}
                        </p>
                      )}

                      {showOnce && othersData.length > 0 ? (
                        <Paper hidden={othersDatahidden}>
                          <div id="presList">
                            {othersData.length > 0 &&
                              othersData.map((x: any) => (
                                <div
                                  key={x.Id}
                                  onClick={() => {
                                    setOthersFromList(x);
                                  }}
                                >
                                  {x.AcctFull_Nm}
                                </div>
                              ))}
                          </div>
                        </Paper>
                      ) : null}
                    </div>
                  )}

                  {optedOut ? (
                    <div className="optedOutModalText">
                      {t("Recipient Opted Out")}
                    </div>
                  ) : null}
                  {/* <CountryDropdown
                    errorFunc={(e) => {
                      console.log(e);
                      setDisabled(e);
                      setPhoneError(e);
                      console.log(disabled);
                      
                      if (e) {
                        setDisableTemplate(assoPrescName.length > 0 && true);
                        brandInputdisable(
                          assoPrescName.length > 0,
                          false,
                          assoPrescName.length > 0
                        );
                      } else {
                        
                        if (
                          (formType && assoPrescName.length <= 0) ||
                          (!formType &&
                            (othersName.length <= 0 ||
                              assoPrescName.length <= 0))
                        ) {
                          setDisabled(true);
                        }
                        setDisableTemplate(assoPrescName.length > 0 && false);
                      }
                    }}
                    initialYN={initialPhone}
                    falsifyShowOnce={falsifyShowOnce}
                    // disableColored={disableBrandInput}
                    valueNumber={othersPhone}
                    onChangeNumber={(e: any) => {
                      setPhoneNumber(e);
                    }}
                    numberLandline={(e: any) => { setNumberLandline(e) }}
                    disableColored={false}
                  /> */}

                  <PhoneNumberDropdown
                    initiall={(e) => {}}
                    errorFunc={(e) => {
                      console.log(e);
                      setDisabled(e);
                      setDisableTemplate(e);
                      setPhoneError(e);
                      console.log(disabled);
                      
                      if (e) {
                        setDisableTemplate(assoPrescName.length > 0 && true);
                        brandInputdisable(
                          assoPrescName.length > 0,
                          false,
                          assoPrescName.length > 0
                        );
                      } else {
                        
                        if (
                          (formType && assoPrescName.length <= 0) ||
                          (!formType &&
                            (othersName.length <= 0 ||
                              assoPrescName.length <= 0))
                        ) {
                          setDisabled(true);
                        }
                      }
                    }}
                    initialYN={initialPhone}
                    falsifyShowOnce={falsifyShowOnce}
                    countryList={countries}
                    // disableColored={disableBrandInput}
                    valueNumber={othersPhone}
                    onChangeNumber={(e: any) => {
                      setPhoneNumber(e);
                    }}
                    numberLandline={(e: any) => {
                      setNumberLandline(e);
                    }}
                    disableColored={discolor}
                  />

                  {formType === false ? (
                    <div className="asso-prescriber-div createContactAssociate">
                      <div>
                        {selectedOthers.Id ? (
                          <div>
                            {selectedOthers.Prescribers.length > 1 ? (
                              <DropdownInupt
                                disableColored={false}
                                keepPlaceHolderAtStart={
                                  selectedOthers.Prescribers.length > 1
                                }
                                placeHolder={t("Select a Prescriber")}
                                label={t("Associated Prescriber") + "*"}
                                data={selectedOthers.Prescribers}
                                id=""
                                name="AcctFull_Nm"
                                func={(e) => {
                                  setAssoPrescName(e.AcctFull_Nm);
                                  setAssociated(e);
                                  brandInputdisable(
                                    othersName.length > 0,
                                    othersPhone.length > 0,
                                    true
                                  );
                                  if (e) setDisableTemplate(false);
                                  if (e) setDisabled(false);
                                }}
                              />
                            ) : (
                              <div>
                                <AssociatedPresList
                                  falsifyShowOnce={falsifyShowOnce}
                                  SelectedValue={
                                    selectedOthers.Prescribers[0].AcctFull_Nm
                                  }
                                  clearDataOnSelect={false}
                                  placeholder={t(
                                    "Search for an Associated Prescriber"
                                  )}
                                  label={t("Associated Prescriber") + "*"}
                                  styleClass="inputEnable"
                                  onChangeInputValue={(e: any) => {
                                    setPrescriberName(e);
                                    if (e == "") {
                                      setDisabled(true);
                                    } else {
                                      setDisabled(false);
                                      setDisableTemplate(false);
                                    }
                                  }}
                                  setAssoPresc={(e: any) => {
                                    console.log(e);
                                    
                                    brandInputdisable(
                                      othersName.length > 0,
                                      othersPhone.length > 0,
                                      true
                                    );
                                    setAssoPrescName(e.AcctFull_Nm);
                                    setAssociated(e);
                                    setDisableTemplate(false);
                                    e ? setDisabled(false) : setDisabled(true);
                                  }}
                                  disable={asscPresInputDisabled}
                                />
                                {}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <AssociatedPresList
                              falsifyShowOnce={falsifyShowOnce}
                              clearDataOnSelect={true}
                              placeholder={t(
                                "Search for an Associated Prescriber"
                              )}
                              label={t("Associated Prescriber") + "*"}
                              styleClass="inputEnable"
                              onChangeInputValue={(e: any) => {
                                setPrescriberName(e);
                                setDisabled(true);
                              }}
                              setAssoPresc={(e: any) => {
                                if (e != "") {
                                  setDisabled(false);
                                  setDisableTemplate(false);
                                  setDisableSMSInput(false);
                                }
                                brandInputdisable(
                                  othersName.length > 0,
                                  othersPhone.length > 0,
                                  true
                                );
                                setAssoPrescName(e.AcctFull_Nm);
                                setAssociated(e);
                                setDisableTemplate(false);
                                if (othersName.length <= 0) {
                                  setDisabled(true);
                                }
                              }}
                              disable={asscPresInputDisabled}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <DropdownInupt
                      activeValue={activeCampaign}
                      disableColored={disableCampaignInput}
                      keepPlaceHolderAtStart={true}
                      placeHolder={t("Select a campaign")}
                      label={t("Campaign") + "*"}
                      data={allCampaign}
                      id=""
                      name="CampaignName"
                      func={(e) => {
                        selectCampaign(e);
                        setDisableTemplate(false);
                      }}
                    />
                  </div>
                  {allWaves.length > 1 ? (
                    <div>
                      <DropdownInupt
                        activeValue={activeWave}
                        disableColored={disableWaveInput}
                        keepPlaceHolderAtStart={allWaves.length > 1}
                        placeHolder={t("Select a wave")}
                        label="Wave*"
                        data={allWaves}
                        id=""
                        name="WaveName"
                        func={(e) => {
                          selectWave(e);
                          setDisableTemplate(false);
                        }}
                      />
                    </div>
                  ) : null}
                  {campaign.length > 1 ? (
                    <div>
                      <DropdownInupt
                        activeValue={activeProduct}
                        disableColored={disableBrandInput}
                        keepPlaceHolderAtStart={campaign.length > 1}
                        placeHolder={t("Select a brand")}
                        label="Brand*"
                        data={campaign}
                        id=""
                        name="ProductName"
                        func={(e) => {
                          selectbrand(e);
                        }}
                      />
                    </div>
                  ) : null}

                  <div>
                    <DropdownInupt
                      notdisabledAfter={true}
                      label={t("SMS Template") + "*"}
                      data={template}
                      keepPlaceHolderAtStart={true}
                      placeHolder={t("Select a Template")}
                      name="TemplateName"
                      disableColored={disableTemplate}
                      disableLater={false}
                      openTemplates={() => {
                        getTemplatesFromCampign();
                      }}
                      func={(e) => {
                        console.log(e);
                        console.log(
                          disabled,
                          e.TemplateId == 0,
                          optedOut,
                          assoPrescName
                        );
                        setIsForm(e.FormId !== null);
                        setTemplateText(e.TemplateBody);
                        setOriginalTemplateText(e.TemplateBody);
                        setTemplateValue(e);
                        setTemplateId(e.TemplateId);
                        setDisabled(false);
                        
                        if (
                          !formType &&
                          (assoPrescName.length <= 0 ||
                            othersName.length <= 0 ||
                            othersPhone.length <= 0)
                        ) {
                          setDisabled(true);
                        }
                        setAssets(e.AssetDetails ? e.AssetDetails : []);
                        setSelectForm(e);
                        setFormValues(e.FieldDetails);
                      }}
                    />
                    {templateText.length > 0 ? (
                      <div
                        className="newchat-floating-form"
                        style={{ display: "block" }}
                      >
                        <textarea
                          name="sms-textarea"
                          id="newchat_sms"
                          value={templateText}
                        />
                        <p>
                          <em>
                            {t("Characters")} : {templateText.length}
                          </em>
                        </p>
                      </div>
                    ) : null}

                    {isForm ? (
                      <div className="template_body_form">
                        <form ref={refForm}>
                          {formsValues.map((x: any, i: any) => (
                            <>
                              <p className="para">
                                {x.FieldName} max( {getRepLimit()} )
                              </p>
                              <textarea
                                style={{ height: "1rem" }}
                                name={x.FieldId}
                                id={x.FieldId}
                                onChange={(e) => {
                                  getFormStatus(e.target.value);
                                }}
                              ></textarea>
                            </>
                          ))}
                          {aocError ? (
                            <span style={{ color: "red", fontSize: "1rem" }}>
                              {t("Previous AOC is pending.")}
                            </span>
                          ) : null}
                          {repQuantityError ? (
                            <h6 style={{ color: "red", fontSize: "1rem" }}>
                              Your available limit is {getRepLimit()}
                            </h6>
                          ) : null}
                          {presQuantityError ? (
                            <h6 style={{ color: "red", fontSize: "1rem" }}>
                              Prescriber's available limit is {getPresLimit()}
                            </h6>
                          ) : null}
                        </form>
                      </div>
                    ) : null}
                    {formsValues.length > 0 && !isForm && (
                      <div className="template_body_form">
                        {formsValues.map((x: any, i: any) => (
                          <>
                            {x.FieldType.toLowerCase() === "checkbox" && (
                              <div className="checkBoxField">
                                <input
                                  required={
                                    x.IsRequired ? x.IsRequired == "Y" : false
                                  }
                                  type="checkbox"
                                  id={x.FieldId}
                                  name={x.FieldId}
                                  onChange={(e) => {
                                    updateFormValue(i, e.target.checked);
                                  }}
                                />
                                <label
                                  htmlFor={x.FieldId}
                                  className="checkboxLabel"
                                >
                                  {x.FieldName}
                                </label>
                              </div>
                            )}
                            {x.FieldType.toLowerCase() === "string" && (
                              <div className="meeting-textfield">
                                <TextField
                                  name={x.FieldId}
                                  id={x.FieldId}
                                  className="roundborder inputEnable"
                                  sx={{ width: 400 }}
                                  required={
                                    x.IsRequired ? x.IsRequired == "Y" : false
                                  }
                                  label={t(x.FieldName)}
                                  onChange={(e) => {
                                    updateFormValue(i, e.target.value);
                                  }}
                                  color="secondary"
                                  focused
                                />
                              </div>
                            )}
                            {x.FieldType.toLowerCase() === "date" && (
                              <TextField
                                name={x.FieldId}
                                id={x.FieldId}
                                required={
                                  x.IsRequired ? x.IsRequired == "Y" : false
                                }
                                className="Datepicker"
                                label={t(x.FieldName)}
                                type="date"
                                placeholder="MM/DD/YYYY"
                                sx={{ width: 220 }}
                                inputProps={{
                                  style: {
                                    height: "84",
                                    padding: "1rem",
                                    color: "grey",
                                  },
                                  min: new Date().toISOString().split("T")[0],
                                }}
                                onChange={(e) => {
                                  let s_date = moment(e.target.value).format(
                                    "MM-DD-YYYY"
                                  );
                                  console.log("s_date", s_date);
                                  updateFormValue(i, s_date);
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                focused
                              />
                            )}
                            {x.FieldType.toLowerCase() === "time" && (
                              <TextField
                                required={
                                  x.IsRequired ? x.IsRequired == "Y" : false
                                }
                                className="Timepicker"
                                name={x.FieldId}
                                id={x.FieldId}
                                label={t(x.FieldName)}
                                type="time"
                                placeholder="--:--"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  style: {
                                    height: "84",
                                    padding: "1rem",
                                    color: "grey",
                                  },
                                }}
                                onChange={(e) => {
                                  let s_time = moment(e.target.value, [
                                    "HH:mm",
                                  ]).format("hh:mm A");
                                  console.log("s_time", s_time, e.target.value);
                                  updateFormValue(i, s_time);
                                }}
                                sx={{ width: 150 }}
                                focused
                              />
                            )}
                          </>
                        ))}
                      </div>
                    )}
                    {templateValue.TemplateFooter &&
                      templateValue.TemplateFooter !== "" && (
                        <div className="template_assets_form">
                          <p className="new-chat-temp-footer">
                            {templateValue.TemplateFooter}
                          </p>
                        </div>
                      )}
                    {assets.length > 0 ? (
                      <div className="template_assets_form">
                        <ul>
                          {assets.map((x: any) => (
                            <li>
                              {" "}
                              <a href={x.AssetFile_Url} target="_blank">
                                {x.Asset_Nm}
                              </a>{" "}
                            </li>
                          ))}
                        </ul>
                        <label htmlFor="">{t("Assets")}</label>
                      </div>
                    ) : null}
                  </div>

                  <GenericButton
                    label={t("Send")}
                    type="submit"
                    disable={
                      disabled ||
                      templateId == 0 ||
                      error ||
                      optedOut ||
                      isNumberLandline ||
                      othersPhone.length == 0
                    }
                    loading={loading}
                  />
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <LogoutModal
        show={overrideModal}
        yorn={overrideFunction}
        modalText={overrideMessage}
        handleCloseParent={(e) => {
          setOverrideModal(false);
        }}
      />
    </div>
  );
}
